import React from 'react';
import { Component, ReactNode } from "react";
import { UserInfo } from "../common/Model";

interface DeletionConfirmationProps {
    userInfo: UserInfo
}

interface DeletionConfirmationState {
}

export default class DeletionConfirmation extends Component<DeletionConfirmationProps, DeletionConfirmationState> {
    getListOfDevices = () => {
        if (this.props.userInfo.devices.length<=0) {
            return (<p>{this.props.userInfo.email} has no registered devices now</p>)
        } else {
            return (
                <div className="list-container">
                    {this.props.userInfo.devices.map((device, idx) => (
                        <p>{device.name}</p>
                    ))}
                </div>
            )
        }
    }
    render(): ReactNode {
        return (
        <div>
                <h2>Deletion completed!</h2>
                <br/>
                <p>These are the devices still enabled for {this.props.userInfo.email}</p>
                <br/>
                {this.getListOfDevices()}
            </div>
        )
    }
}