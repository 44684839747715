import React from 'react';
import { Component, ReactNode } from "react";
import GetEmail from "./GetEmail";
import SelectDevices from "./SelectDevices";
import DeletionConfirmation from "./DeletionConfirmation";
import { State, UserInfo } from "../common/Model";

export default class ClearDevices extends Component {
    state: State = {
        step: 0,
        userInfo: undefined
    }

    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    // proceed to the next step
    nextStep = (userInfo: UserInfo) => {
        const { step } = this.state;
        this.setState({ 
            step: step + 1,
            userInfo: userInfo
         });
    }

    render(): ReactNode {
        switch (this.state.step) {
            case 0:
                return (
                    <GetEmail nextStep={ userInfo => { this.nextStep(userInfo) } }/>
                )
            case 1:
                return (
                    <SelectDevices userInfo={ this.state.userInfo! } nextStep={ userInfo => { this.nextStep(userInfo) }}/>
                )
            case 2:
                return (
                    <DeletionConfirmation userInfo={ this.state.userInfo! }/>
                )
            default:
                return (
                    <div>Oops, we had an error?</div>
                )
        }
    }
}