import React from 'react';
import { Device, GetDevicesRequestDevice, UserInfo } from "../common/Model";
import { Component, ReactNode } from "react";

enum GetEmailLoadingState {
    writing,
    fetching,
    error,
    done,
}
interface GetEmailState {
    loadingState: GetEmailLoadingState
    email?: string,
    devices?: Device[],
    error?: string
}

interface GetEmailProps {
    nextStep: (arg0: UserInfo) => void
}

// export default class GetEmail extends Component {
export default class GetEmail extends Component<GetEmailProps, GetEmailState> {
    constructor(props: GetEmailProps) {
        super(props);
        this.state = { loadingState: GetEmailLoadingState.writing, email: '', devices: undefined, error: undefined }
    }
    handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
        if (this.state.loadingState !== GetEmailLoadingState.writing &&
            this.state.loadingState !== GetEmailLoadingState.error) {
            return;
        }
        this.setState({
            loadingState: this.state.loadingState,
            email: e.currentTarget.value,
            devices: undefined,
            error: undefined
        })
    }
    getStatusTitle = () => {
        switch (this.state.loadingState) {
            case GetEmailLoadingState.error: return 'An error happened';
            case GetEmailLoadingState.done: return 'Found devices';
            case GetEmailLoadingState.fetching: return 'Loading devices...';
            case GetEmailLoadingState.writing: return '';
        }
    }
    submit = async () => {
        try {
            this.setState({
                loadingState: GetEmailLoadingState.fetching,
                email: this.state.email,
                devices: undefined,
                error: undefined
            })
            const url: string = 'https://us-central1-musclem.cloudfunctions.net/v1_getUserDevices?username=' + this.state.email;
            const fetchedResponse = await fetch(url, {
                method: 'get',
                headers: {
                    'Accept': 'application/json'
                },
            });
            if (fetchedResponse.status !== 200) {
                this.setState({
                    loadingState: GetEmailLoadingState.error,
                    email: this.state.email,
                    devices: undefined,
                    error: fetchedResponse.status === 401 ? 'Email not found' : 'Unknown error from server'
                })
                return;
            }
            const dto: GetDevicesRequestDevice[] = await fetchedResponse.json();
            this.setState({
                loadingState: GetEmailLoadingState.done,
                email: this.state.email,
                devices: dto.map((devDto) => {
                    return {
                        name: devDto.name,
                        uuid: devDto.uuid
                    }
                }),
                error: undefined
            })
            let userInfo: UserInfo = {
                email: this.state.email!,
                devices: dto
            }
            this.props.nextStep(userInfo)
        } catch (error) {
            this.setState({
                loadingState: GetEmailLoadingState.error,
                email: this.state.email,
                devices: undefined,
                error: typeof error === 'string' ? error :  'unknown error'
            })
        }
    }
    render(): ReactNode {
        return (
            <div>
                <h2>Type the email of the account you want to <br/> see the current devices and remove them if necessary</h2>
                <form
                    onSubmit={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        this.submit()
                    }}>
                    <label>Email&nbsp;&nbsp;&nbsp;
                        <input
                            type="text"
                            placeholder="email address"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </label>
                    <br/>
                    <br/>
                    <button type="submit">See the lis of devices</button>
                </form>
                <br/>
                <br/>
                <div>
                {this.getStatusTitle()}
                </div>
                <div>{this.state.error}</div>
                <div>{this.state.devices?.map((dev) => { return dev.name }).join(', ')}</div>
            </div>
        )
    }
}