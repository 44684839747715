import React from 'react';
import { Component, ReactNode } from "react";
import { GetDevicesRequestDevice, UserInfo } from "../common/Model";

interface SelectDevicesProps {
    userInfo: UserInfo,
    nextStep: (arg0: UserInfo) => void
}

enum SelectDevicesLoadingState {
    selecting,
    removing,
    error,
    done,
}
interface SelectDevicesState {
    loadingState: SelectDevicesLoadingState
    selectedIndices: string[]
    newUserInfo?: UserInfo,
    error?: string
}

export default class SelectDevices extends Component<SelectDevicesProps, SelectDevicesState> {
    constructor(props: SelectDevicesProps) {
        super(props);
        this.state = { loadingState: SelectDevicesLoadingState.selecting, selectedIndices: [], newUserInfo: undefined }
    }
    handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
        let uuid = e.currentTarget.id;
        var newIndices = this.state.selectedIndices;
        const foundIndex = newIndices.indexOf(uuid);
        if (foundIndex === -1) {
          newIndices.push(uuid);
        } else {
          newIndices.splice(foundIndex, 1);
        }
        this.setState({
            loadingState: SelectDevicesLoadingState.selecting,
            selectedIndices: newIndices,
            newUserInfo: undefined
        })
    }
    submit = async () => {
        try {
            if (this.state.selectedIndices.length == 0) {
                this.setState({
                    loadingState: SelectDevicesLoadingState.error,
                    selectedIndices: this.state.selectedIndices,
                    newUserInfo: undefined,
                    error: 'please select at leas one device'
                })
            }
            this.setState({
                loadingState: SelectDevicesLoadingState.removing,
                selectedIndices: this.state.selectedIndices,
                newUserInfo: undefined,
                error: undefined
            })
            var errors: string[] = [];
            var dto: GetDevicesRequestDevice[] = [];
            for (const uuid of this.state.selectedIndices) {
                const url: string = 'https://us-central1-musclem.cloudfunctions.net/v1_deleteUserDevice?username=' +
                    this.props.userInfo.email + '&deviceId=' + uuid;
                const fetchedResponse = await fetch(url, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json'
                    },
                });
                if (fetchedResponse.status !== 200) {
                    errors.push('Could not delete ' + uuid);
                } else {
                    dto = await fetchedResponse.json();
                }
            }
            if (errors.length > 0) {
                this.setState({
                    loadingState: SelectDevicesLoadingState.error,
                    selectedIndices: this.state.selectedIndices,
                    newUserInfo: undefined,
                    error: errors.join(', ')
                })
            } else {
                this.props.nextStep({
                    email: this.props.userInfo.email,
                    devices: dto
                })
            }
        } catch (error) {
            this.setState({
                loadingState: SelectDevicesLoadingState.error,
                selectedIndices: this.state.selectedIndices,
                newUserInfo: undefined,
                error: typeof error === 'string' ? error :  'unknown error'
            })
        }
    }
    getListOfDevices = () => {
        if (this.props.userInfo.devices.length<=0) {
            return (<p>{this.props.userInfo.email} has no registered devices</p>)
        } else {
            return (
                <div>
                    <div className="list-container">
                        {this.props.userInfo.devices.map((device, idx) => (
                            <div>
                                <input type="checkbox" id={device.uuid} onChange={this.handleChange} />
                                {device.name}
                                <br />
                            </div>
                        ))}
                    </div>
                    <br />
                    <button type="submit">Delete selected devices</button>
                </div>
            )
        }
    }
    render(): ReactNode {
        return (
            <div>
                <h2>Select the devices you want to delete<br/> for {this.props.userInfo.email}</h2>
                <form
                    onSubmit={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        this.submit()
                    }}>
                        {this.getListOfDevices()}
                </form>
                <br/>
                <br/>
                <div>{this.state.error}</div>
            </div>
        )
    }
}